// eslint-disable-next-line import/prefer-default-export
export function parallaxPosition(target) {
  /** 畫面的滾動比例 */
  const scrolltotop = document.scrollingElement.scrollTop;
  const scrollTotal = document.body.clientHeight;
  const ratio = scrolltotop / scrollTotal;

  /** parallax position calculating */
  const targetHeight = target.clientHeight;
  const tinyOffset = targetHeight * (2 / 3); // e.g. 總移動距離為容器的 2/3
  const xPos = 'center';
  const yPos = 0 - (tinyOffset * ratio);
  return `${xPos} ${yPos}px`;
}

export function zoomInByScroll() {
  /** 畫面的滾動比例 */
  const scrolltotop = document.scrollingElement.scrollTop;
  const totalHeight = document.body.clientHeight;
  const portHeight = window.innerHeight;
  const scrollableTotal = totalHeight - portHeight;

  const ratio = scrolltotop / scrollableTotal;

  return ratio;
}
