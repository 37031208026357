<template>
  <div class="form_select_custom" @click="isActive = !isActive">
    <div
      class="cover"
      :class="{ active: isActive }"
    ></div>
    <div class="custom-select" :class="{ open: isActive }">

      <!-- 目前選項 -->
      <div class="custom-select__trigger">
        <span class="small">{{ selected || placeHolder }}</span>

        <!-- 箭頭樣式 -->
        <div v-if="customArrow" class="customArrow">
          <NextArrow
            fillColor="#C4C4C4"
          />
        </div>
        <div v-else class="arrow"></div>
      </div>

      <!-- 所有選項 -->
      <div class="custom-options">
        <span
          class="custom-option small"
          @click="selectHandler(placeHolder, null)"
        >{{ placeHolder }}</span>

        <!-- 動態選項 -->
        <template v-if="dynamicOptions">
          <span
            v-for="(option, index) in dynamicOptions"
            :key="index"
            class="custom-option small"
            @click="selectHandler(option)"
          >
            {{ option }}
          </span>
        </template>

        <!-- 靜態選項 -->
        <template v-else>
          <span
            v-for="(option, idx) in options"
            :key="`option_${idx}`"
            class="custom-option small"
            :class="{ selected: option.value === value }"
            :value="option.value"
            @click="selectHandler(option)"
          >{{ optionName(option) }}</span>
        </template>

      </div>
    </div>

    <!-- error message -->
    <div
      v-show="errorMsg"
      class="error_msg txt-red small-x"
    >{{ errorMsg }}</div>
  </div>
</template>

<script>
import _ from 'lodash';
import NextArrow from '@/components/icon/NextArrow.vue';
import languageMixin from '@/mixins/languageMixin';

export default {
  name: 'FormSelectCustom',
  mixins: [languageMixin],
  props: {
    customArrow: {
      type: Boolean,
      default: false,
    },
    format: {
      type: Object,
      default: undefined,
    },
    selected: {
      type: String,
      default: undefined,
    },
    dynamicOptions: {
      type: Array,
      default: undefined,
    },
  },
  components: {
    NextArrow,
  },
  data() {
    return {
      isActive: false,
      optionSelected: {},
      value: null,
      errorMsg: '',
    };
  },
  computed: {
    placeHolder() {
      return _.get(this.format, 'placeholder', null);
    },
    options() {
      return _.get(this.format, 'options', null);
    },
    optionSelectedName() {
      return this.convertToEnglish(this.optionSelected.label, this.optionSelected.label_en);
    },
    colorText() {
      if (this.options) {
        const target = this.options.filter((option) => option.value === this.selected)[0];
        if (target) {
          return target.label;
        }
      }
      return '';
    },
    hasError() {
      if (this.format.required && this.errorMsg !== '') return true;
      return false;
    },
  },
  methods: {
    optionName(option) {
      return this.convertToEnglish(option.label, option.label_en);
    },
    selectHandler(option) {
      this.$emit('update:selected', option.value);
      this.value = option.value;
      this.optionSelected = option;
    },
    validate(val = this.selected) {
      if (this.format.required) {
        if (!val || val === this.placeHolder) {
          return '必選欄位!';
        }
        return '';
      }
      return '';
    },
    test() {
      this.errorMsg = this.validate(this.selected) ? this.validate(this.selected) : '';
    },
  },
  watch: {
    selected() {
      this.test();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.form_select_custom {
  position: relative;
  user-select: none;
  width: 200px;
  padding-bottom: 10px;
  &.simpleBorder {
      .custom-select {
          border-width: 0;
          .custom-select__trigger {
              border-width: 0 0 1px 0;
              }
          }
  }
  .cover {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    &.active {
      display: block;
    }
  }
  .icon_box {
      svg {
          margin-right: 1px;
      }
  }
  .custom-select {
      position: relative;
      display: flex;
      flex-direction: column;
      border-width: 0 1px 0 1px;
      border-style: solid;
      border-color: $color-light_gray;
  }
  .custom-select__trigger {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      color: #3b3b3b;
      height: 44px;
      line-height: 44px;
      background: #ffffff;
      cursor: pointer;
      border-width: 1px 0 1px 0;
      border-style: solid;
      border-color: $color-light_gray;
  }
  .custom-options {
      position: absolute;
      display: block;
      top: 100%;
      left: -1px;
      right: -1px;
      border: 1px solid $color-light_gray;
      border-top: 0;
      background: #fff;
      transition: all 0.5s;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      z-index: 2;
      max-height: 250px;
      box-shadow: 0px 0px 10px #0000000D;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: $color-gray;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
  }
  .custom-select.open .custom-options {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
  }
  .custom-option {
      position: relative;
      display: block;
      padding: 0 12px;
      color: #000;
      line-height: 44px;
      font-size: 1rem;
      cursor: pointer;
      transition: all 0.5s;
  }
  .custom-option:hover {
      cursor: pointer;
      background-color: #b2b2b2;
  }
  .custom-option.selected {
      color: $color-red;
      background-color: #fff;
  }
  .arrow {
      position: relative;
      height: 6px;
      width: 6px;
  }
  .arrow::before, .arrow::after {
      content: "";
      position: absolute;
      bottom: 0px;
      width: 0.15rem;
      height: 100%;
      transition: all 0.5s;
  }
  .arrow::before {
      left: -2px;
      transform: rotate(-45deg);
      background-color: #394a6d;
  }
  .arrow::after {
      left: 2px;
      transform: rotate(45deg);
      background-color: #394a6d;
  }
  .open .arrow::before {
      left: -2px;
      transform: rotate(45deg);
  }
  .open .arrow::after {
      left: 2px;
      transform: rotate(-45deg);
  }
  .customArrow {
    svg {
      vertical-align: middle;
      transform: rotate(90deg);
    }
  }
  .open .customArrow {
    svg {
      transform: rotate(270deg);
    }
  }
}
</style>
