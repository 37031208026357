<template>
  <div
    id="banner"
    :style="{
      backgroundImage: `url('${img}')`,
      backgroundSize: backgroundSizeRatio,
    }"
  >
    <img
      v-if="showDeco"
      class="banner_deco"
      src="../../assets/imgs/banner/banner_deco.svg"
      alt=""
    />

    <div
      class="container w1400"
      :class="{ 'container_contentPage' :  !isMainPage}">
      <!-- 主頁用 -->
      <div class="banner_text txt-white" v-if="isMainPage">
        <div class="banner_text_title h1">{{ title }}</div>
        <div class="banner_text_subtitle h5">{{ subtitle }}</div>
      </div>

      <!-- 內容頁用 -->
      <div v-else class="banner_page_text txt-white txt-center">
        <p>{{ subtitle }}</p>
        <p class="h3">{{ title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { zoomInByScroll } from '@/lib/public';

export default {
  name: 'Banner',
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    img: {
      type: String,
    },
    showDeco: {
      type: Boolean,
      default: true,
    },
    isMainPage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      base: 1,
      ratio: 0,
    };
  },
  computed: {
    backgroundSizeRatio() {
      return `${(this.base + this.ratio * 1.2) * 100}%`;
    },
  },
  methods: {
    setBase() {
      if (window.screen.width <= 800 && window.screen.width > 600) {
        this.base = 1.5;
      } else if (window.screen.width <= 600 && window.screen.width > 480) {
        this.base = 2;
      } else if (window.screen.width <= 480) {
        this.base = 2.5;
      } else {
        this.base = 1;
      }
    },
    scrollHandler() {
      this.setBase();
      this.ratio = zoomInByScroll();
    },
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler);
  },
  mounted() {
    this.setBase();
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
};
</script>

<style lang="scss" scoped>
#banner {
  position: relative;
  width: 100%;
  padding-bottom: 18.23%;
  background: center/cover no-repeat;
  .banner_deco {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
  }
  .container {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    &.container_contentPage {
      top: 50%;
    }
  }
  @media screen and (max-width: 1200px) {
    padding-bottom: 210px;
  }
  @media screen and (max-width: 768px) {
    .banner_deco {
      width: 180px;
    }
    .container {
      top: 50%;
      text-align: center;
    }
    .banner_page_text {
      .h3 {
        font-size: 1.5rem;
        line-height: 1.5;
      }
    }
  }
}
</style>
